import { supportedChain } from "@/config/chains";
import { endpointToAdapterOptions, isClientSide } from "@/utils";
import { createContext, FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import { TonClient } from "ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import axios from "axios";

// copied from @tonx/adapter

function isPostMethod(method: string) {
  const postMethods = new Set(["runGetMethod", "sendBoc", "sendBocReturnHash", "sendQuery"]);
  return postMethods.has(method);
}

function appendSearchParam(url: string, params: Record<string, string>): string {
  const urlObj = new URL(url);

  for (const [k, v] of Object.entries(params)) {
    if (v == null) continue;
    urlObj.searchParams.append(k, v);
  }
  return urlObj.toString();
}

export type TonState = TonClient | undefined;

const initialState: TonState = undefined;

export const TonContext = createContext<TonState>(initialState);

type TonProviderProps = {
  children: JSX.Element;
};

const TonProvider: FunctionComponent<TonProviderProps> = ({ children }) => {
  const [ton, setTon] = useState<TonClient>();

  useEffect(() => {
    const handler = async () => {
      if (!isClientSide()) {
        return undefined;
      }

      if (!supportedChain) {
        return undefined;
      }

      let endpoint = supportedChain.tonCenterUrl;

      const adapterOptions = endpointToAdapterOptions(endpoint);

      setTon(
        new TonClient({
          endpoint,
          httpAdapter: async (config) => {
            const { method, params } = JSON.parse(config.data);
            if (method) {
              config.url = config.url!.replace(adapterOptions.apiKey, method);
              if (isPostMethod(method)) {
                config.data = JSON.stringify(params);
              } else {
                config.url = appendSearchParam(config.url, params);
                config.method = "GET";
              }
            }
            (config.headers ??= {}).API_KEY = adapterOptions.apiKey;
            delete config.headers["X-Ton-Client-Version"];

            const r = await axios.defaults.adapter!(config);

            const response = JSON.parse(r.data);

            if (response.result) {
              response.ok = true;
            }

            r.data = JSON.stringify(response);

            return r;
          },
        }),
      );
    };
    handler();
  }, []);

  return <TonContext.Provider value={ton}>{children}</TonContext.Provider>;
};

export default memo(TonProvider);
