import { supportedChain } from "@/config/chains";
import { isClientSide, endpointToAdapterOptions } from "@/utils";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { createContext, FunctionComponent, memo, useEffect, useMemo, useState } from "react";
import TonWeb from "tonweb";
import { TonWebAdapter } from "@tonx/adapter";

export type TonwebState = TonWeb | undefined;

const initialState: TonwebState = undefined;

export const TonwebContext = createContext<TonwebState>(initialState);

type TonwebProviderProps = {
  children: JSX.Element;
};

const TonwebProvider: FunctionComponent<TonwebProviderProps> = ({ children }) => {
  const [tonweb, setTonweb] = useState<TonWeb>();

  useEffect(() => {
    const handler = async () => {
      if (!isClientSide()) {
        return undefined;
      }

      if (!supportedChain) {
        return undefined;
      }

      let endpoint = supportedChain.tonCenterUrl;
      setTonweb(new TonWebAdapter(endpointToAdapterOptions(endpoint)) as unknown as TonWeb);
    };
    handler();
  }, []);

  return <TonwebContext.Provider value={tonweb}>{children}</TonwebContext.Provider>;
};

export default memo(TonwebProvider);
